import Image from "next/image";
import { useState } from "react";
import { useRouter } from "next/router";
import Spinner from "components/spinner";
import Logo from "public/logoSymbola_blue500.png";
import { LockClosedIcon, XCircleIcon } from "@heroicons/react/solid";
import { signIn } from "next-auth/react";
import Link from "next/link";
import GoogleG from "public/GoogleG.svg";

//TODO: Align acceptance of terms/privacy.  Currently it is overdone rather than underdone, which is fine.  But may have some redundancy once I sort out invitees, sharing, etc.

export default function SignIn() {
  const router = useRouter();

  const { callbackUrl, error } = router.query;

  let callback: string = "/app";
  if (callbackUrl) {
    callback = callbackUrl.toString();
  } // if router passes specific Url as query param, override default

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (provider: string) => {
    if (provider === "google") {
      signIn("google", { callbackUrl: callback });
    } else {
      signIn("email", {
        email,
        redirect: false,
        callbackUrl: callback,
      });
      setSubmitted(true);
    }
  };

  if (!router.isReady) {
    return (
      <div className="flex min-h-screen bg-gray-50 items-center justify-center ">
        <Spinner />
      </div>
    );
  }

  if (submitted) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col max-w-md w-full  ">
          <div className="flex flex-col justify-center items-center">
            <Image height={46} width={36.6} src={Logo} alt="Symbola Logo" />
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
              Please check your email for a secure confirmation link to
              continue.
            </h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col max-w-md w-full  ">
        <div className="flex flex-col justify-center items-center">
          {/* Per next-auth, a user cannot register as EMAIL first then sign in with GOOGLE (using same email).
          An attempt to do so throws this error in query / callbackUrl, which I am handling here.
          Note that for account recovery, next-auth does allow the reverse (Google register; then sign in with email) */}
          {error === "OAuthAccountNotLinked" && (
            <div className="rounded-md bg-red-50 p-4 mb-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    There was an issue with your login attempt:
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc pl-5 space-y-1">
                      <li>
                        It looks like you tried to log in using Google, but an
                        account may already exist using this email address.
                      </li>
                      <li>
                        Please sign in with your email address to continue. If
                        the problem persists, please contact support.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Image height={46} width={36.6} src={Logo} alt="Symbola Logo" />
          <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
            Log In to Your Symbola Account{" "}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white pt-8 pb-6 px-4 shadow rounded-lg sm:px-8">
            <div>
              <button
                onClick={() => handleSubmit("google")}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {" "}
                <div className="absolute left-0.5     inset-y-0 flex items-center ">
                  <div className=" bg-white  rounded-sm w-8 h-8 mx-auto my-auto flex content-center justify-center ">
                    <Image
                      priority
                      src={GoogleG}
                      alt="Google G Logo"
                      width={17}
                      height={17}
                    />
                  </div>
                </div>
                Continue with Google
              </button>
            </div>

            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or</span>
              </div>
            </div>

            <form className="space-y-6" onSubmit={() => handleSubmit("email")}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {" "}
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                      aria-hidden="true"
                    />
                  </span>
                  Continue
                </button>
              </div>
            </form>

            <div className="mt-4">
              <p className="font-light text-center	 text-xs text-gray-600">
                Don&apos;t have a Symbola account yet?{" "}
                <Link href="/register">
                  <a className="text-blue-500 hover:text-blue-600">Sign Up</a>
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
